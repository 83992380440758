/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.30/esri/copyright.txt for details.
*/
import { _ as r } from "../../chunks/tslib.es6.js";
import { JSONSupport as o } from "../../core/JSONSupport.js";
import { property as t } from "../../core/accessorSupport/decorators/property.js";
import "../../core/has.js";
import "../../core/Logger.js";
import "../../core/RandomLCG.js";
import { subclass as e } from "../../core/accessorSupport/decorators/subclass.js";
import { getJsonType as s } from "../../geometry/support/jsonUtils.js";
import { srToRESTValue as i } from "../../geometry/support/spatialReferenceUtils.js";
let a = class extends o {
  constructor(r) {
    super(r), this.geometries = [], this.outSpatialReference = null, this.transformation = null, this.transformForward = null;
  }
  toJSON() {
    const r = this.geometries.map(r => r.toJSON()),
      o = this.geometries[0],
      t = {};
    return t.outSR = i(this.outSpatialReference), t.inSR = i(o.spatialReference), t.geometries = JSON.stringify({
      geometryType: s(o),
      geometries: r
    }), this.transformation && (t.transformation = this.transformation.wkid || JSON.stringify(this.transformation)), null != this.transformForward && (t.transformForward = this.transformForward), t;
  }
};
r([t()], a.prototype, "geometries", void 0), r([t({
  json: {
    read: {
      source: "outSR"
    }
  }
})], a.prototype, "outSpatialReference", void 0), r([t()], a.prototype, "transformation", void 0), r([t()], a.prototype, "transformForward", void 0), a = r([e("esri.rest.support.ProjectParameters")], a);
const p = a;
export { p as default };